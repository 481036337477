import { useMediaQuery, useTheme } from "@mui/material";
import "./style.css";
import { forwardRef } from "react";

export const Clients = forwardRef<HTMLDivElement>((props, ref) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      style={{
        backgroundColor: "#2B69A4",
        width: "80%",
        marginLeft: "10%",
        borderRadius: "10px",
        marginTop: "2rem",
        marginBottom: "4rem",
      }}
      ref={ref}
    >
      <div className="title-client-container">
        <p className="who-we-are-title" style={{ color: "#FFF" }}>
          nuestros clientes
        </p>
        <p className="who-we-are-translation" style={{ color: "#F1F1F1" }}>
          /OUR CLIENTS
        </p>
      </div>
      {!matches && (
        <>
          <div className="row-container">
            <div className="logo-cliente logo-cliente-1" />
            <div className="logo-cliente logo-cliente-2" />
            <div className="logo-cliente logo-cliente-3" />
            <div className="logo-cliente logo-cliente-4" />
          </div>
          <div className="row-container">
            <div className="logo-cliente logo-cliente-5" />
            <div className="logo-cliente logo-cliente-6" />
            <div className="logo-cliente logo-cliente-7" />
            <div className="logo-cliente logo-cliente-8" />
          </div>
          <div className="row-container">
            <div className="logo-cliente logo-cliente-9" />
            <div className="logo-cliente logo-cliente-10" />
            <div className="logo-cliente logo-cliente-11" />
            <div className="logo-cliente logo-cliente-12" />
          </div>
          <div className="row-container">
            <div className="logo-cliente logo-cliente-drillar" />
          </div>
        </>
      )}
      {matches && (
        <>
          <div className="row-container">
            <div className="logo-cliente logo-cliente-1" />
            <div className="logo-cliente logo-cliente-2" />
          </div>
          <div className="row-container">
            <div className="logo-cliente logo-cliente-3" />
            <div className="logo-cliente logo-cliente-4" />
          </div>
          <div className="row-container">
            <div className="logo-cliente logo-cliente-5" />
            <div className="logo-cliente logo-cliente-6" />
          </div>
          <div className="row-container">
            <div className="logo-cliente logo-cliente-7" />
            <div className="logo-cliente logo-cliente-8" />
          </div>
          <div className="row-container">
            <div className="logo-cliente logo-cliente-9" />
            <div className="logo-cliente logo-cliente-10" />
          </div>
          <div className="row-container">
            <div className="logo-cliente logo-cliente-11" />
            <div className="logo-cliente logo-cliente-12" />
          </div>
          <div className="row-container">
            <div className="logo-cliente logo-cliente-drillar" />
          </div>
        </>
      )}
    </div>
  );
});

import Carousel from "react-material-ui-carousel";
import { useMediaQuery, useTheme } from "@mui/material";
import "./style.css";
import { forwardRef } from "react";

interface Item {
  number?: string;
  description?: string;
  translation?: string;
  img?: string;
  autor?: string;
}

const items: Item[] = [
  {
    number: "1",
    description: "Traslados de muestras / testigos",
    translation: "Tranfer of sample/cores",
    img: "/assets/img/servicios/muestrasYtestigos.jpg",
    autor: "Foto de Erik Kroon Unsplash",
  },
  {
    number: "2",
    description: "Traslados de equipos de perforación",
    translation: "Transfer of drilling equipment",
    img: "/assets/img/servicios/equiposDePerforacion.jpg",
    autor: "Foto de Worksite Ltd. Unsplash",
  },
  {
    number: "3",
    description: "Traslados de cargas generales (aditivos insumos)",
    translation: "Transfer of general cargo",
    img: "/assets/img/servicios/cargasGenerales.jpeg",
    autor: "Foto de Mincho Kavaldzhiev Unsplash",
  },
  {
    number: "4",
    description: "Movilizaciones de campamentos",
    translation: "Camp movilizations",
    img: "/assets/img/servicios/campamentos.jpg",
    autor: "Foto de Pawan Sharma Unsplash",
  },
  {
    number: "5",
    description: "Alquiler de vehículos 4x4 / Cisterna",
    translation: "Rental of 4x4 vehicles",
    img: "/assets/img/servicios/cisterna.jpg",
    autor: "Foto de Lance Asper Unsplash",
  },
  {
    number: "6",
    description: "Transporte de equipos viales",
    translation: "Transport of road equipment",
    img: "/assets/img/servicios/maquinasViales.jpeg",
    autor: "Foto de Fons Heijnsbroek Unsplash",
  },
  {
    number: "7",
    description: "Camionetas guías",
    translation: "Pick-up trucks",
    img: "/assets/img/servicios/camionetasGuia.jpg",
    autor: "Foto de Fons Heijnsbroek Unsplash",
  },
];

const Services = forwardRef<HTMLDivElement>((props, ref) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div
      style={{
        backgroundColor: "#2B69A4",
        height: "100vh",
      }}
      ref={ref}
    >
      <div
        style={{
          padding: "2rem 0 0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
        }}
      >
        <p className="who-we-are-title" style={{ color: "#FFF" }}>
          servicios
        </p>
        <p className="who-we-are-translation" style={{ color: "#F1F1F1" }}>
          /SERVICES
        </p>
      </div>
      <Carousel
        autoPlay={true}
        animation={"fade"}
        indicators={true}
        duration={500}
        navButtonsAlwaysVisible={matches ? true : false}
        navButtonsAlwaysInvisible={false}
        cycleNavigation={true}
        fullHeightHover={true}
        swipe={true}
        indicatorContainerProps={{ style: { margin: "0px", width: "auto" } }}
        activeIndicatorIconButtonProps={{ style: { color: "#fff" } }}
        sx={{ width: "80%", marginLeft: "10%" }}
      >
        {items.map((item, i) => (
          <Card
            key={i}
            number={item.number}
            description={item.description}
            translation={item.translation}
            img={item.img}
            autor={item.autor}
          />
        ))}
      </Carousel>
    </div>
  );
});

const Card = (item: Item) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
        marginLeft: "10%",
        height: "80vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="text-carousel-container">
          <div
            style={{
              margin: "2rem",
            }}
          >
            <p className="service-number">{item.number}</p>
            <p className="service-title">{item.description}</p>
            <p className="service-translation">{item.translation}</p>
          </div>
        </div>
        {matches && (
          <div className="img-carousel-container">
            <img
              src={item.img}
              alt={item.autor}
              style={{
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
              className="img-carousel"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Services;

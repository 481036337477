import './logo.style.css'

type LogoProps = {
  vertical?: boolean
}

export const Logo = ({vertical}: LogoProps) => {
  const layout = vertical ? "logoContainerVertical" : "logoContainerHorizontal"

  return (
    <div className={layout}>
      <img src={"../assets/img/logo.png"} alt="Logo" className="logo" />
      <p className="navbarTitle">logistica bachi</p>
    </div>
  );
};

import CardContent from "@mui/material/CardContent";
import Carousel from "react-material-ui-carousel";
import { Container, useMediaQuery, useTheme } from "@mui/material";
import "./style.css";
import { forwardRef } from "react";

interface Equipment {
  title?: string;
  translation?: string;
  type?: string;
  models?: string;
  img?: string;
}

const items: Equipment[] = [
  {
    title: "Equipos livianos",
    translation: "/LIGHT EQUIPMENT",
    type: "toyota hilux",
    models: "4x4",
    img: "/assets/img/equipos/hilux.jpg",
  },
  {
    title: "Equipos medianos",
    translation: "/MEDIUM EQUIPMENT",
    type: "ford 4000",
    models: "4x4",
    img: "/assets/img/equipos/ford4000.jpg",
  },
  {
    title: "Equipos medianos",
    translation: "/MEDIUM EQUIPMENT",
    type: "hino",
    models: " ",
    img: "/assets/img/equipos/hino.jpg",
  },
  {
    title: "Equipos medianos",
    translation: "/MEDIUM EQUIPMENT",
    type: "mercedez benz",
    models: "atego 4x4",
    img: "/assets/img/equipos/mbAtego.jpg",
  },
  {
    title: "Equipos pesados / semiremolque",
    translation: "/HEAVY / SEMITRAILER",
    type: "mercedez benz",
    models: "astros 6x4",
    img: "/assets/img/equipos/mbAstro.jpeg",
  },
  {
    title: "Equipos pesados / semiremolque",
    translation: "/HEAVY / SEMITRAILER",
    type: "iveco",
    models: "trucker 6x4",
    img: "/assets/img/equipos/ivecoTrucker6x4.jpg",
  },
  {
    title: "Equipos pesados / semiremolque",
    translation: "/HEAVY / SEMITRAILER",
    type: "scania",
    models: "4x4",
    img: "/assets/img/equipos/scania4x4.jpg",
  },
  {
    title: "Equipos pesados",
    translation: "/HEAVY EQUIPMENT",
    type: "renault",
    models: " kerax 6x4",
    img: "/assets/img/equipos/renaultKerax.jpg",
  },
  {
    title: "Equipos pesados",
    translation: "/HEAVY EQUIPMENT",
    type: "scania",
    models: "8x4",
    img: "/assets/img/equipos/scania8x4.jpg",
  },
  {
    title: "Equipos pesados",
    translation: "/HEAVY EQUIPMENT",
    type: "scania",
    models: "6x6",
    img: "/assets/img/equipos/pesado.jpg",
  },
  {
    title: "Equipos pesados",
    translation: "/HEAVY EQUIPMENT",
    type: "scania",
    models: "hidro 8x4",
    img: "/assets/img/equipos/scaniaHidrp8x4.png",
  },
];

const Equipments = forwardRef<HTMLDivElement>((props, ref) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
      }}
      ref={ref}
    >
      <Carousel
        autoPlay={true}
        animation={"fade"}
        indicators={true}
        duration={500}
        navButtonsAlwaysVisible={matches ? true : false}
        navButtonsAlwaysInvisible={false}
        cycleNavigation={true}
        fullHeightHover={true}
        swipe={true}
        indicatorContainerProps={{ style: { margin: "20px", width: "auto" } }}
        activeIndicatorIconButtonProps={{ style: { color: "#fff" } }}
        sx={{ width: "80%", marginLeft: "10%" }}
      >
        {items.map((item, i) => (
          <Item
            key={i}
            title={item.title}
            translation={item.translation}
            type={item.type}
            models={item.models}
            img={item.img}
          />
        ))}
      </Carousel>
    </div>
  );
});

const Item = (item: Equipment) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container sx={{ maxWidth: "100%" }}>
      <CardContent
        sx={{
          height: matches ? "30vh" : "40vh",
          backgroundColor: matches ? "#fff" : "#d9d9d9",
          display: "flex",
          flexDirection: "column",
          justifyContent: matches ? "inherit" : "center",
        }}
      >
        <div className="equipment-title-container">
          <p className="equipment-title">{item.title}</p>
          <p className="equipment-translation">{item.translation}</p>
        </div>
        <div className="equipment-type-container">
          <p
            className={
              item.type === "hino"
                ? "equipment-type equipment-type-hino"
                : item.type === "mercedez benz"
                ? "equipment-type equipment-type-mercedez"
                : "equipment-type"
            }
          >
            {item.type}
          </p>
          <p className="equipment-model">{item.models}</p>
        </div>
      </CardContent>
      <div
        className="equipment-img-container"
        style={{
          backgroundImage: `url(${item.img})`,
          backgroundPosition:
            item.type === "iveco" ||
            item.type === "toyota hilux" ||
            item.type === "hino"
              ? "right 0% bottom 30%"
              : item.models === "6x6"
              ? "right 0% bottom 60%"
              : "center",
        }}
      />
      {/* {item.type === "toyota hilux" && <img src={item.img} alt="hilux" className="equipment-img" />} */}
      {/* </div> */}
    </Container>
  );
};

export default Equipments;

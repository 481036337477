import Grid from "@mui/material/Unstable_Grid2";
import "./style.css";
import { Avatar, useMediaQuery, useTheme } from "@mui/material";
import { forwardRef } from "react";

const Mision = forwardRef<HTMLDivElement>((props, ref) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div ref={ref}>
      <Grid container spacing={0} sx={{ flexGrow: 1 }}>
        <Grid xs={10} xsOffset={1} md={5} mdOffset={1} xl={4} xlOffset={3}>
          <div
            style={{
              margin: "1rem 0 -1rem 0",
              display: "flex",
              justifyContent: "row",
              alignItems: "baseline",
            }}
          >
            <p className="mision-title">Visión</p>
            <p className="mision-translation">/VISION</p>
          </div>
          <p className="mision-subtitle align-left">
            Ser reconocida como una empresa líder en servicios de transporte y
            logística por nuestra productividad y alta calidad. Buscando
            reconocimiento y diferenciación en la prestación de nuestros
            servicios superando así las expectativas de nuestros clientes.
          </p>
          <p className="mision-translation align-left">
            To be recognized as a leading company in transport and logistic
            services for presenting productivity and high quality. Seeking
            recognition and diferentiation in the provision of our services,
            thus exceeding the expectation of our clients.
          </p>
        </Grid>
        {matches && (
          <Grid
            md={4}
            mdOffset={1}
            xl={3}
            xlOffset={0}
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="Chofer"
              src="../assets/img/chofer-celular.png"
              sx={{ width: "20rem", height: "20rem", mt: 3 }}
            />
          </Grid>
        )}
        {matches && (
          <Grid
            md={4}
            mdOffset={1}
            xl={3}
            xlOffset={2}
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="Camiones"
              src="../assets/img/camiones.jpg"
              sx={{ width: "20rem", height: "20rem", mt: -6 }}
            />
          </Grid>
        )}
        <Grid
          xs={10}
          xsOffset={1}
          md={5}
          mdOffset={1}
          xl={4}
          xlOffset={0}
          mt={matches ? 4 : 0}
        >
          <div
            style={{
              margin: "1rem 0 -1rem 0",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "baseline",
            }}
          >
            <p className="mision-title align-right">Misión</p>
            <p className="mision-translation">/MISSION</p>
          </div>
          <p className="mision-subtitle align-right">
            Contribuir al éxito de nuestros clientes prestando nuestros
            servicios con altos estándares de calidad, enfocándonos en la
            productividad y tecnología.
          </p>
          <p className="mision-translation align-right">
            Contribute to the success of our clients by providing our services
            with high quality standards, focusing on productivity and technology
          </p>
        </Grid>
        <Grid
          xs={10}
          xsOffset={1}
          md={5}
          mdOffset={1}
          mb={2}
          xl={4}
          xlOffset={3}
        >
          <div
            style={{
              margin: "1rem 0 -1rem 0",
              display: "flex",
              justifyContent: "row",
              alignItems: "baseline",
            }}
          >
            <p className="mision-title align-left">Valores</p>
            <p className="mision-translation">/VALUES</p>
          </div>
          <img
            src="../assets/img/valores.png"
            alt="valores"
            className="values-img"
          />
        </Grid>
        {matches && (
          <Grid
            md={4}
            mdOffset={1}
            xl={3}
            xlOffset={0}
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="Camion"
              src="../assets/img/camion-bachi.png"
              sx={{ width: "20rem", height: "20rem", mb: 2 }}
            />
          </Grid>
        )}
        <div
          style={{
            margin: "auto",
            paddingLeft: "3rem",
            paddingRight: "3rem",
            maxWidth: "50%",
            paddingBottom: "5rem",
            paddingTop: "3rem",
          }}
        >
          <div
            style={{
              margin: "1rem 0 -1rem 0",
              display: "flex",
              flexDirection: matches ? "row" : "column",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <h2
              style={{
                fontFamily: "Bebas Neue",
                fontSize: "32px",
                fontWeight: 500,
                color: "#000",
                textAlign: "center",
              }}
            >
              Nuestro Compromiso con la Comunidad
            </h2>
            <p
              className="mision-translation"
              style={{
                marginTop: "-4px",
                alignSelf: !matches ? "center" : "baseline",
              }}
            >
              /OUR COMMITMENT TO COMMUNITY
            </p>
          </div>
          <p
            className="mision-subtitle align-center"
            style={{ marginTop: "2rem" }}
          >
            En Logística Bachi, nos enorgullecemos de contribuir al bienestar de
            las comunidades en las que operamos. Recientemente, hemos apoyado
            una iniciativa para proporcionar un invierno más cálido a los
            alumnos de Calingasta.{" "}
            <a
              href="https://latinmining.com/articulos/un-invierno-mas-calido-para-alumnos-calingastinos"
              target="_blank"
            >
              Leer más
            </a>
          </p>
        </div>
      </Grid>
    </div>
  );
});

export default Mision;

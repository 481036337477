import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import "./style.css";
import IconButton from "@mui/material/IconButton";
import { Logo } from "../Logo";
import { Button } from "@mui/material";

type Anchor = "left";

const TemporaryDrawer: React.FC<{
  handleNavigate: (section: string) => void;
}> = ({ handleNavigate }) => {
  const [state, setState] = React.useState({
    left: false,
  });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      if (open) {
        setState({ ...state, [anchor]: true });
      } else {
        setTimeout(() => {
          setState({ ...state, [anchor]: false });
        }, 700);
      }
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: 250,
        backgroundColor: "#2B69A4",
        height: "100vh",
        color: "#fff",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            marginBottom: "2rem",
          }}
        >
          <Logo />
        </div>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ lineHeight: 0 }}
            onClick={() => handleNavigate("whoWeAre")}
          >
            <p className="navbarSubTitle">quienes somos</p>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ lineHeight: 0 }}
            onClick={() => handleNavigate("services")}
          >
            <p className="navbarSubTitle">servicios</p>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ lineHeight: 0 }}
            onClick={() => handleNavigate(`equipments`)}
          >
            <p className="navbarSubTitle">equipos</p>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ lineHeight: 0 }}
            onClick={() => handleNavigate(`clients`)}
          >
            <p className="navbarSubTitle">clientes</p>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ lineHeight: 0 }}
            onClick={() => handleNavigate(`footer`)}
          >
            <p className="navbarSubTitle">contacto</p>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const CustomIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.status.primary,
  }));

  const CustomTextButton = styled(Button)(({ theme }) => ({
    color: theme.status.primary,
  }));

  return (
    <div>
      {matches && (
        <React.Fragment>
          <div className="navbarContainer">
            <Logo vertical={false} />
            <div className="navbarSubtitleContainer">
              <CustomTextButton
                variant="text"
                onClick={() => handleNavigate("whoWeAre")}
              >
                Quienes Somos
              </CustomTextButton>
              <CustomTextButton
                variant="text"
                onClick={() => handleNavigate("services")}
              >
                Servicios
              </CustomTextButton>
              <CustomTextButton
                variant="text"
                onClick={() => handleNavigate("equipments")}
              >
                Equipos
              </CustomTextButton>
              <CustomTextButton
                variant="text"
                onClick={() => handleNavigate("clients")}
              >
                Clientes
              </CustomTextButton>
              <CustomTextButton
                variant="text"
                onClick={() => handleNavigate("footer")}
              >
                Contacto
              </CustomTextButton>
            </div>
          </div>
        </React.Fragment>
      )}
      {!matches &&
        (["left"] as const).map((anchor) => (
          <React.Fragment key={anchor}>
            <div className="navbarContainer">
              <Logo />
              <CustomIconButton
                aria-label="delete"
                size="large"
                onClick={toggleDrawer(anchor, true)}
              >
                <MenuIcon />
              </CustomIconButton>
            </div>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
    </div>
  );
};

export default TemporaryDrawer;

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Wellcome } from "./sections/Wellcome";
import { WhoWeAre } from "./sections/WhoWeAre";
import Mision from "./sections/Mision";
import Services from "./sections/Services";
import Equipments from "./sections/Equipment";
import { Footer } from "./sections/Footer";
import { Clients } from "./sections/Clients";
import { useRef } from "react";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      primary: string;
    };
  }

  interface ThemeOptions {
    status?: {
      primary?: string;
    };
  }
}

const theme = createTheme({
  status: {
    primary: "#FFF",
  },
});

const App = () => {
  const whoWeAreRef = useRef<HTMLDivElement>(null);
  const misionRef = useRef<HTMLDivElement>(null);
  const servicesRef = useRef<HTMLDivElement>(null);
  const equipmentsRef = useRef<HTMLDivElement>(null);
  const clientsRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const handleNavigate = (section: string) => {
    let targetRef = null;

    if (section === "whoWeAre") {
      console.log("who we are");
      targetRef = whoWeAreRef;
    } else if (section === "mision") {
      console.log("mission");
      targetRef = misionRef;
    } else if (section === "services") {
      console.log("services");
      targetRef = servicesRef;
    } else if (section === "equipments") {
      console.log("equipments");
      targetRef = equipmentsRef;
    } else if (section === "clients") {
      console.log("clients");
      targetRef = clientsRef;
    } else if (section === "footer") {
      console.log("footer");
      targetRef = footerRef;
    }

    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Wellcome handleNavigate={handleNavigate} />
      <WhoWeAre ref={whoWeAreRef} />
      <Mision ref={misionRef} />
      <Services ref={servicesRef} />
      <Equipments ref={equipmentsRef} />
      <Clients ref={clientsRef} />
      <Footer ref={footerRef} />
    </ThemeProvider>
  );
};

export default App;

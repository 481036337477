import ReactPlayer from "react-player/lazy";
import "./style.css";
import { Divider, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { forwardRef} from "react";

export const WhoWeAre = forwardRef<HTMLDivElement>((props, ref) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div className="who-we-are-container" ref={ref}>
      <div className="who-we-are-container-titles">
        <div
          style={{
            margin: "2rem 0 0 0",
            display: "flex",
            justifyContent: "row",
            alignItems: "baseline",
          }}
        >
          <p className="who-we-are-title">¿QUIENES SOMOS?</p>
          <p className="who-we-are-translation">/WHO WE ARE</p>
        </div>
        <div style={{ maxWidth: "630px", margin: "-1.5rem 0 -1rem 0" }}>
          <p className="who-we-are-subtitle">
            Somos una compañía argentina que provee servicios tanto de
            transporte como logística a los sectores de minería, petróleo y gas
          </p>
        </div>
        <div style={{ maxWidth: "630px" }}>
          <p className="who-we-are-translation">
            We are an Argentine company that provides transport and logistics
            services to the mining, oil and gas sectors
          </p>
        </div>
      </div>
      <Grid container spacing={0} mt={3} justifyContent={"center"}>
        <Grid xs={12} md={8} lg={8} xl={5}>
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=fYstZSCu10E"
              width="100%"
              height="100%"
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "3rem",
          width: "100%",
        }}
      >
        <Grid xs={10} md={5} xl={3}>
          <p className="who-we-are-history-first-column">
            Nuestra empresa inicia actividad en el año 1996 estableciendo una
            relación laboral con BHP mineral en el proyecto Agua Rica donde el
            ingeniero Nivaldo Rojas confió en nuestros desarollo de soluciones
            logísticas con especialidad en el transporte terrestre y servicios
            mineros en alta montaña
          </p>
        </Grid>
        {matches && (
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ color: "#000" }}
          />
        )}
        <Grid xs={10} md={5} xl={3}>
          <p className="who-we-are-history-second-column">
            El dia de hoy hemos llegado a trabajar a lo largo y ancho de la
            Coordillera de Los Andes, ampliando nuestros servicios y aumentando
            nuestros equipos de trabajo utilizando la ultima tecnología
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "2rem",
          width: "100%",
        }}
      >
        <Grid xs={10} md={5} xl={3}>
          <p className="who-we-are-history-translation-first-column">
            Our company began activity in 1996 establishing a labor relationship
            with BHP mineral at Agua Rica project where the engineer Nivaldo
            Rojas trusted our development. logistics solutions specializing in
            land transport and mining services in high mountains.
          </p>
        </Grid>
        {matches && (
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ color: "#000" }}
          />
        )}
        <Grid xs={10} md={5} xl={3}>
          <p className="who-we-are-history-translation-second-column">
            Today we have come to work all over the Cordillera de Los Andes,
            expanding our services and increasing our work teams using the
            latest technology
          </p>
        </Grid>
      </Grid>
    </div>
  );
});

import "./style.css";
import TemporaryDrawer from "../../components/Navbar";

export const Wellcome: React.FC<{
  handleNavigate: (section: string) => void;
}> = ({ handleNavigate }) => {
  return (
    <div className="container">
      <TemporaryDrawer handleNavigate={handleNavigate}/>
      <div className="titleContainer">
        <h1 className="sub-title">Más de 26 años</h1>
        <h1 className="title">Cumpliendo</h1>
        <h1 className="title">Objetivos</h1>
      </div>
    </div>
  );
};

import "./style.css";
import { Logo } from "../../components/Logo";
import { forwardRef } from "react";

export const Footer = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <>
      <div className="footer-container" ref={ref}>
        <div className="footer-info-container">
          <Logo vertical={true} />
        </div>
        <div className="footer-info-container contact-container">
          <p className="contact-title">contacto</p>
          <p>info@logisticabachi.com</p>
          <p>+54 9 2614 69-7862</p>
        </div>
      </div>
      <div
        style={{ backgroundColor: "#1C4E9E", display: "flex", width: "98%", padding: '1%', justifyContent: 'center' }}
      >
        <a
          href="https://www.danielmontilla.tech"
          target="_blank"
          style={{
            color: "#fff",
            textDecoration: "none",
            fontFamily: "Bebas Neue",
            fontSize: "12px",
          }}
        >
          Site Made by Daniel Montilla
        </a>
      </div>
    </>
  );
});
